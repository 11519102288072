/* Ace Editor */

.ace_editor {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	height: 300px;
	width: 100%;
}