// FIX tabelle responsive per Safari iOS
.table-responsive {
  -webkit-overflow-scrolling: auto;
}

// FIX Cruddable
.dropdown {
  &.cruddable {
    .dropdown-toggle:after {
      color: #000 !important;
    }
  }
}

// CRUD div-style
.table-container {
  margin: 10px 0;
  .row {
    margin: 0 !important;
    &.tr {
      font-size: 0.9em;
      border: 1px solid #dadada;
    }

    &.head {
      font-weight: bold;
      @media (max-width: 767px) {
        display: none;
      }

      .th {
        background: #eaeaea;
        padding: 5px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.operations {
          min-width: 155px;
          max-width: 155px;
        }
        &.order {
          min-width: 55px;
          max-width: 55px;
        }
      }
    }

    &.body {
      .td {
        margin: auto;
        padding: 5px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.operations {
          overflow: unset;
          min-width: 155px;
          max-width: 155px;
        }
      }
      .th {
        background: #eaeaea;
        padding: 5px 10px;
        &.order {
          min-width: 55px;
          max-width: 55px;
        }
      }

      @media (max-width: 767px) {
        border: 1px solid #dadada;
        margin-bottom: 10px;
        .td {
          text-align: right;
          width: 100%;
          border-bottom: 1px dotted #dadada;
          &.operations {
            min-width: unset;
            max-width: unset;
          }
        }
        .td[data-th]:before {
          content: attr(data-th);
          font-weight: bold;
          width: 33%;
          display: inline-block;
          text-align: left;
          float: left;
        }
      }
    }
  }
}

.container.alert-notification {
  background: white;
  border: 1px solid #ccd0d4;
  padding: 10px;
  color: black;
  margin-bottom: 10px;
  @each $color, $value in $theme-colors {
    &.alert-notification-#{$color} {
      border-left: 5px solid $value;
    }
  }
}
