// REDEFINE BOOTSTRAP
$primary: #BA0707;
$secondary: #998b8b;


// REDEFINE STARADMIN
$sidebar-gradient-color-1: #8E0A0A;
$sidebar-gradient-color-2: #BA0707;
$sidebar-light-menu-hover-bg: darken($sidebar-gradient-color-1, 20%);
$sidebar-light-submenu-line-color: darken($sidebar-gradient-color-1, 18%);
$sidebar-light-menu-hover-bg: darken($sidebar-gradient-color-1, 20%);
$sidebar-light-submenu-line-color: darken($sidebar-gradient-color-1, 18%);

$sidebar-light-menu-active-bg: rgba(darken($sidebar-gradient-color-1, 10%), 0.25);
$sidebar-light-menu-active-color: #fff;
$sidebar-dark-menu-active-bg: rgba(lighten($sidebar-gradient-color-1, 10%), 0.25);
$sidebar-dark-menu-active-color: #fff;

$sidebar-dark-bg: darken($sidebar-gradient-color-1, 18%);
$sidebar-dark-menu-hover-bg: darken($sidebar-gradient-color-1, 30%);