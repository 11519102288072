/* iCheck plugin Flat skin
----------------------------------- */
.icheckbox_flat,
.iradio_flat {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(flat.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat {
    background-position: 0 0;
}
    .icheckbox_flat.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat {
    background-position: -88px 0;
}
    .iradio_flat.checked {
        background-position: -110px 0;
    }
    .iradio_flat.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat,
    .iradio_flat {
        background-image: url(flat@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}

/* red */
.icheckbox_flat-red,
.iradio_flat-red {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(red.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-red {
    background-position: 0 0;
}
    .icheckbox_flat-red.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-red.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-red.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-red {
    background-position: -88px 0;
}
    .iradio_flat-red.checked {
        background-position: -110px 0;
    }
    .iradio_flat-red.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-red.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-red,
    .iradio_flat-red {
        background-image: url(red@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}

/* green */
.icheckbox_flat-green,
.iradio_flat-green {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(green.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-green {
    background-position: 0 0;
}
    .icheckbox_flat-green.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-green.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-green.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-green {
    background-position: -88px 0;
}
    .iradio_flat-green.checked {
        background-position: -110px 0;
    }
    .iradio_flat-green.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-green.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-green,
    .iradio_flat-green {
        background-image: url(green@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}

/* blue */
.icheckbox_flat-blue,
.iradio_flat-blue {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(blue.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-blue {
    background-position: 0 0;
}
    .icheckbox_flat-blue.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-blue.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-blue.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-blue {
    background-position: -88px 0;
}
    .iradio_flat-blue.checked {
        background-position: -110px 0;
    }
    .iradio_flat-blue.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-blue.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-blue,
    .iradio_flat-blue {
        background-image: url(blue@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}

/* aero */
.icheckbox_flat-aero,
.iradio_flat-aero {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(aero.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-aero {
    background-position: 0 0;
}
    .icheckbox_flat-aero.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-aero.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-aero.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-aero {
    background-position: -88px 0;
}
    .iradio_flat-aero.checked {
        background-position: -110px 0;
    }
    .iradio_flat-aero.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-aero.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-aero,
    .iradio_flat-aero {
        background-image: url(aero@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}

/* grey */
.icheckbox_flat-grey,
.iradio_flat-grey {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(grey.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-grey {
    background-position: 0 0;
}
    .icheckbox_flat-grey.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-grey.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-grey.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-grey {
    background-position: -88px 0;
}
    .iradio_flat-grey.checked {
        background-position: -110px 0;
    }
    .iradio_flat-grey.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-grey.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-grey,
    .iradio_flat-grey {
        background-image: url(grey@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}

/* orange */
.icheckbox_flat-orange,
.iradio_flat-orange {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(orange.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-orange {
    background-position: 0 0;
}
    .icheckbox_flat-orange.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-orange.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-orange.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-orange {
    background-position: -88px 0;
}
    .iradio_flat-orange.checked {
        background-position: -110px 0;
    }
    .iradio_flat-orange.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-orange.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-orange,
    .iradio_flat-orange {
        background-image: url(orange@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}

/* yellow */
.icheckbox_flat-yellow,
.iradio_flat-yellow {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(yellow.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-yellow {
    background-position: 0 0;
}
    .icheckbox_flat-yellow.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-yellow.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-yellow.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-yellow {
    background-position: -88px 0;
}
    .iradio_flat-yellow.checked {
        background-position: -110px 0;
    }
    .iradio_flat-yellow.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-yellow.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-yellow,
    .iradio_flat-yellow {
        background-image: url(yellow@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}

/* pink */
.icheckbox_flat-pink,
.iradio_flat-pink {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(pink.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-pink {
    background-position: 0 0;
}
    .icheckbox_flat-pink.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-pink.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-pink.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-pink {
    background-position: -88px 0;
}
    .iradio_flat-pink.checked {
        background-position: -110px 0;
    }
    .iradio_flat-pink.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-pink.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-pink,
    .iradio_flat-pink {
        background-image: url(pink@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}

/* purple */
.icheckbox_flat-purple,
.iradio_flat-purple {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(purple.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-purple {
    background-position: 0 0;
}
    .icheckbox_flat-purple.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-purple.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-purple.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-purple {
    background-position: -88px 0;
}
    .iradio_flat-purple.checked {
        background-position: -110px 0;
    }
    .iradio_flat-purple.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-purple.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-purple,
    .iradio_flat-purple {
        background-image: url(purple@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}