/* Auth */

.full-page-wrapper {
  min-height: 100vh;
  padding-left: 0;
  padding-right: 0;
}

.auth {
  min-height: 100vh;

  &.auth-bg-1 {
    background: url("../../../assets/images/auth/login_1.jpg");
    background-size: cover;
  }

  &.register-bg-1 {
    background: url("../../../assets/images/auth/register.jpg") center center no-repeat;
    background-size: cover;
  }

  &.theme-one {
    .auto-form-wrapper {
      background: $card-bg;
      padding: 40px;
      border-radius: 4px;
      box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);

      .form-group {
        .input-group {
          height: 44px;

          .form-control {
            border: 1px solid darken($border-color, 5%);
            border-radius: 6px;
            height: 44px;
            line-height: 4px;
            display: inline-block;
            padding-top: 0px;
            padding-bottom: 0px;

            &:focus {
              border-color: darken($border-color, 5%);
            }
          }

          .input-group-append {
            border-left: none;

            .input-group-text {
              border-radius: 0 6px 6px 0;
              border-left: none;
              border-color: darken($border-color, 5%);
              color: #b6b6b6;
            }
          }
        }

        .submit-btn {
          font-family: $type-1;
          font-size: 0.78em;
          padding: 12px 8px;
          font-weight: 600;
        }
      }

      .g-login {
        border: 1px solid $border-color;
        padding: 13px;
        font-size: 0.72em;
        font-weight: 600;
        background: transparent;
      }
    }

    .auth-footer {
      list-style-type: none;
      padding-left: 0;
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;

      li {
        margin-right: 10px;
        line-height: 1;
        padding-right: 10px;
        border-right: 1px solid rgba(255, 255, 255, 0.4);

        &:last-child {
          margin-right: 0;
          border-right: none;
        }

        a {
          font-size: 0.78em;
          color: rgba(255, 255, 255, 0.4);
        }
      }

      @media (max-width: $grid-breakpoints-sm) {
        justify-content: center;
      }
    }

    .footer-text {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &.theme-two {
    .auto-form-wrapper {
      position: relative;
      height: 100vh;
      min-height: 100vh;
      max-height: 100vh;
      padding: 110px 5% 5%;
      border-radius: 4px;

      @media (max-width: $grid-breakpoints-sm) {
        padding: 11% 15px;
        text-align: center;
        height: 100%;
        max-height: 100%;
      }

      .nav-get-started {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: 30px;
        right: 30px;

        @media (max-width: $grid-breakpoints-sm) {
          margin-bottom: 5%;
          margin-right: auto;
          margin-left: auto;
          position: relative;
          top: unset;
          right: unset;
          justify-content: center;
        }

        p {
          margin-bottom: 0;
          font-weight: 300;
        }

        .get-started-btn {
          border: 1px solid $border-color;
          padding: 10px 20px;
          font-size: 0.72em;
          font-weight: 600;
          color: $body-color;
          margin-left: 20px;
          border-radius: 50px;
        }
      }

      form {
        width: 50%;
        min-width: 300px;
        max-width: 480px;

        .form-group {
          width: 100%;
          margin-bottom: 25px;

          @media (max-width: $grid-breakpoints-sm) {
            margin-right: auto;
            margin-left: auto;
          }

          .input-group {
            height: 44px;

            .form-control {
              border: 1px solid darken($border-color, 5%);
              border-left: none;
              border-radius: 0 6px 6px 0;
              height: 45px;

              &:focus {
                border-left: none;
                border-color: darken($border-color, 5%);
              }
            }

            .input-group-prepend {
              .input-group-text {
                border-radius: 6px 0 0 6px;
                border-color: darken($border-color, 5%);
                border-right: none;
                color: #dfdfdf;
              }
            }
          }

          .submit-btn {
            font-family: $type-1;
            font-size: 0.78em;
            padding: 11px 33px;
            font-weight: 600;
            background-image: $blue-teal-gradient;
          }
        }
      }

      .footer-text {
        font-size: 0.78em;
        margin-bottom: 0;
      }

      .auth-footer {
        list-style-type: none;
        display: flex;
        margin-top: 7px;
        padding-left: 0;
        margin-bottom: 0;

        li {
          margin-right: 10px;
          line-height: 1;
          padding-right: 10px;
          border-right: 1px solid $text-muted;

          &:last-child {
            margin-right: 0;
            border-right: none;
          }

          a {
            font-size: 0.78em;
            color: $text-muted;
          }
        }

        @media (max-width: $grid-breakpoints-sm) {
          justify-content: center;
        }
      }
    }

    .banner-section {
      padding-right: 0;

      .slide-content {
        width: 100%;

        &.bg-1 {
          background: url("../../../assets/images/auth/login_2.jpg") no-repeat center center;
          background-size: cover;
        }

        &.bg-2 {
          background: url("../../../assets/images/auth/register_2.jpg") no-repeat center center;
          background-size: cover;
        }
      }
    }
  }

  &.multi-step-login {
    height: 100%;
    min-height: 100vh;
    background: $content-bg;

    .step-form {
      position: relative;
      width: 530px;
      margin: 50px auto;
      text-align: center;

      .step-progress {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 30px;
        overflow: hidden;
        counter-reset: step;
        display: flex;
        justify-content: space-around;

        li {
          position: relative;
          list-style-type: none;
          color: $body-color;
          font-size: 1.08em;
          font-weight: 500;
          float: left;

          &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            content: counter(step);
            counter-increment: step;
            width: 30px;
            height: 30px;
            line-height: 20px;
            font-size: 096em;
            font-weight: 600;
            color: $body-color;
            background: darken($content-bg, 5%);
            border-radius: 100px;
            margin: 0 auto 5px auto;
          }

          &.active {
            &:before {
              background: theme-color(success);
              color: #fff;
            }
          }

          &:first-child:after {
            content: none;
          }
        }
      }

      fieldset {
        background: $card-bg;
        border-radius: 10px;
        padding: 30px 30px;
        box-sizing: border-box;
        margin: 0 auto;
        width: 100%;
        position: relative;

        &:not(:first-of-type) {
          display: none;
        }
      }

      .form-control {
        height: 40px;
        border-radius: 4px;
      }
    }
  }
}