$line-height-base: 1.5 !default;
$border-width: 1px !default;
////////// COLOR SYSTEM //////////
$blue: #00aeef !default;
$indigo: #6610f2 !default;
$purple: #ab8ce4 !default;
$pink: #E91E63 !default;
$red: #ff0017 !default;
$orange: #fb9678 !default;
$yellow: #ffd500 !default;
$green: #3bd949 !default;
$teal: #58d8a3 !default;
$cyan: #57c7d4 !default;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$white-smoke: #f4f4f4 !default;
$ghost-white: #f7fafc !default;
$violet: #41478a !default;
$darkslategray: #2e383e !default;
$dodger-blue: #3498db !default;
$primary: #2196f3 !default;
$secondary: #dde4eb !default;
$success: #19d895 !default;
$info: #8862e0 !default;
$warning: #ffaf00 !default;
$danger: #ff6258 !default;
$light: #fbfbfb !default;
$dark: #252C46 !default;
$blue-teal-gradient: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
$blue-teal-gradient-light: linear-gradient(120deg, rgba(0, 228, 208, 0.7), rgba(89, 131, 232, 0.7));
$theme-colors: (primary: $primary, secondary: $secondary, success: $success, info: $info, warning: $warning, danger: $danger, light: $light, dark: $dark) !default;
$colors: (blue: $blue, indigo: $indigo, purple: $purple, pink: $pink, red: $red, orange: $orange, yellow: $yellow, green: $green, teal: $teal, cyan: $cyan, white: $white, white-smoke: #f3f5f6, gray: $gray-600, gray-light: #8ba2b5, gray-lightest: #f7f7f9, gray-dark: #292b2c) !default;
////////// COLOR SYSTEM //////////
$card-bg: $white !default;
////////// SOCIAL COLORS //////////
$social-colors: (twitter: #4DBFF5, facebook: #648ACA, google: #E55F4B, linkedin: #0177b4, pinterest: #cc2127, youtube: #e52d27, github: #333333, behance: #1769ff, dribbble: #ea4c89, reddit: #ff4500) !default;
////////// SOCIAL COLORS //////////
////////// FONTS //////////
$type-1: 'roboto',
sans-serif !default;
$type-2: $type-1 !default;
$default-font-size: 0.875rem !default; //14px
$text-muted: #858585 !default;
$text-gray: #4d4c4c !default;
$body-color: #001737 !default;
$border-color: #d5dfe6 !default;
$accent-color: #fff !default;
////////// FONT VARIABLES //////////
////////// BOOTSTRAP BREAKPOINTS //////////
$grid-breakpoints-xs: 0px !default;
$grid-breakpoints-sm: 576px !default;
$grid-breakpoints-md: 768px !default;
$grid-breakpoints-lg: 992px !default;
$grid-breakpoints-xl: 1200px !default;
////////// BOOTSTRAP BREAKPOINTS //////////

////////// COLOR VARIABLES //////////
$content-bg: #F3F3F3 !default;
////////// COLOR VARIABLES //////////

///////// SETTINGS PANEL ////////
$settings-panel-width: 300px !default;

///////// BUTTONS ////////
$button-fixed-width: 120px !default;
$btn-padding-y: 0.4rem !default;
$btn-padding-x: 1rem !default;
$btn-line-height: 1 !default;
$btn-padding-y-xs: .5rem !default;
$btn-padding-x-xs: .75rem !default;
$btn-padding-y-sm: 0.50rem !default;
$btn-padding-x-sm: 0.81rem !default;
$btn-padding-y-lg: 0.94rem !default;
$btn-padding-x-lg: 1.94rem !default;
$btn-font-size: .875rem !default;
$btn-font-size-xs: .625rem !default;
$btn-font-size-sm: .875rem !default;
$btn-font-size-lg: .875rem !default;
$btn-border-radius: .1875rem !default;
$btn-border-radius-xs: .1875rem !default;
$btn-border-radius-sm: .1875rem !default;
$btn-border-radius-lg: .1875rem !default;
$social-btn-padding: 13px !default;
$social-btn-icon-size: 1rem !default;
///////// BUTTONS ////////
/////////  FORMS /////////
$input-bg: lighten($card-bg, 4%);
$input-border-radius: 2px !default;
$input-placeholder-color: #c9c8c8 !default;
$input-font-size: .9rem !default;
$input-padding-y: .56rem !default;
$input-padding-x: 1.375rem !default;
$input-line-height: 1 !default;
$input-padding-y-sm: .5rem !default;
$input-padding-x-sm: .81rem !default;
$input-line-height-sm: 1 !default;
$input-padding-y-lg: .94rem !default;
$input-padding-x-lg: 1.94rem !default;
$input-line-height-lg: 1 !default;
$custom-input-height: 42px !default;
$custom-switch-width: 2.25rem !default;
$custom-control-indicator-size: 1.25rem !default;
$custom-switch-indicator-size: 1rem !default;
$custom-switch-indicator-border-radius: 50px !default;
$custom-control-indicator-checked-bg: #33ac2e !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;
$form-check-label-input-helper-bg: theme-color(light);
///////// FORMS /////////
////////  DROPDOWNS ///////
$dropdown-bg: #fff !default;
$dropdown-hover-bg: theme-color(light) !default;
$dropdown-hover-color: $body-color !default;
$dropdown-divider-bg: $border-color !default;
$dropdown-link-color: $body-color !default;
$dropdown-header-color: $body-color !default;
$dropdown-toggle-arrow-color: #fff !default;
////////  DROPDOWNS ///////
//////// TABLES ////////
$table-hover-bg: #edf2f7 !default;
$table-color: $body-color !default;
$table-cell-padding: 12px 15px !default;
$table-head-cell-color: $body-color !default;
$border-cell-color: $border-color !default;
$table-border-color: $border-color !default;
$table-accent-bg: theme-color(light);
$table-inverse-bg: #2a2b32 !default;
$table-inverse-color: color(white);
$table-striped-order: even !default;
//////// TABLES ////////
////////// MEASUREMENT AND PROPERTY VARIABLES //////////
$boxed-container-width: 1200px !default;
$border-property: 1px solid $border-color !default;
$card-bg: #fff !default;
$card-spacing-y: 1.875rem !default;
$card-padding-y: 1.88rem !default;
$card-padding-x: 1.81rem !default;
$grid-gutter-width: 25px !default;
$action-transition-duration: 0.25s !default;
$action-transition-timing-function: ease !default;
////////// OTHER VARIABLES //////////
////////// BREAD CRUMBS VARIABLES //////////
// default styles
$breadcrumb-padding-y: 0.56rem !default;
$breadcrumb-padding-x: 1.13rem !default;
$breadcrumb-item-padding: .5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-font-size: $default-font-size !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-border-color: $border-color !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-700 !default;
$breadcrumb-divider: "/" !default;
$breadcrumb-item-color: $black !default;
// custom styles
$breadcrumb-custom-padding-y: 0 !default;
$breadcrumb-custom-padding-x: 0 !default;
$breadcrumb-custom-item-padding-y: 0.56rem !default;
$breadcrumb-custom-item-padding-x: 10px !default;
$breadcrumb-item-bg: #dbe3e6 !default;
////////// BREAD CRUMBS VARIABLES //////////
////////// MODALS VARIABLES //////////
$modal-inner-padding: 1rem !default;
$modal-dialog-margin: 10px !default;
$modal-dialog-margin-y-sm-up: 30px !default;
$modal-title-line-height: $line-height-base !default;
$modal-content-bg: $content-bg !default;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, .5) !default;
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, .5) !default;
$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: $border-color !default;
$modal-content-border-color: $border-color !default;
$modal-footer-border-color: $border-color !default;
$modal-header-border-width: $border-width !default;
$modal-content-border-width: $border-width !default;
$modal-footer-border-width: $border-width !default;
$modal-header-padding-x: 26px !default;
$modal-header-padding-y: 25px !default;
$modal-body-padding-x: 26px !default;
$modal-body-padding-y: 35px !default;
$modal-footer-padding-x: 31px !default;
$modal-footer-padding-y: 15px !default;
$modal-lg: 90% !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;
$modal-transition: transform .4s ease !default;
////////// MODALS VARIABLES //////////
////////// TOOLTIP VARIABLES //////////
//default styles
$tooltip-font-size: .75rem !default;
$tooltip-padding-y: .4rem !default;
$tooltip-padding-x: .75rem !default;
$tooltip-border-radius: .375rem !default;
$tooltip-bg: theme-color(dark);
$tooltip-arrow-color: $tooltip-bg !default;
////////// TOOLTIP VARIABLES //////////
////////// POPOVER VARIABLES //////////
//default styles
$popover-bg: lighten($card-bg, 4%);
$popover-text-color: $black !default;
$popover-border-color: $border-color !default;
$popover-arrow-color: $popover-bg !default;
$popover-arrow-outer-color: $popover-border-color !default;
$popover-header-bg: $popover-bg !default;
////////// POPOVER VARIABLES //////////
////////// EMAIL VARIABLES //////////
$email-view-wrapper-bg: $card-bg !default;
$emal-menu-item-hover-color: rgba(240, 244, 249, 0.8) !default;
$emal-menu-item-active-bg: rgba(theme-color(primary), 0.3) !default;
$emal-menu-item-active-color: theme-color(primary);
////////// EMAIL VARIABLES //////////

$avgrund-popin-bg: #fff !default;
$avgrund-close-color: #fff !default;
$progressbar-bg: darken($content-bg, 5%);

$tour-bg: #fff !default;
$popover-title-bg: theme-color(primary);
$popover-title-color: #fff !default;

$chartjs-tooltip-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.6) !default;


$colcade-grid-bg: rgba(77, 199, 160, 0.2) !default;
$colcade-grid-bg-item: rgb(77, 199, 160) !default;

$context-menu-item-color: $black !default;
$context-menu-hover-bg: $black !default;
$context-menu-hover-color: #fff !default;

$dropify-wrapper-hover-bg: linear-gradient(135deg, #F2F7F8 25%, transparent 25%, transparent 50%, #F2F7F8 50%, #F2F7F8 75%, transparent 75%, transparent);
$dropify-infos-bg: rgba(255, 255, 255, .9) !default;
$dropify-infos-inner-filename-color: theme-color(primary);
$dropify-infos-message: #333 !default;
$dropify-clear-bg: #fff !default;
$dropify-clear-color: color(gray);

$jvectormap-container: $white !default;


$noUi-base-bg: color(gray-lightest);
$noUi-connect-bg: theme-color(primary);
$noUi-origin-bg: theme-color(success);

$select2-selected-item-bg: theme-color(primary);

$inputtag-tab-bg: theme-color(primary);
$inputtag-tab-color: #fff !default;

$typeahead-bg: #fff !default;
$tt-query-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !default;
$typeahead-tt-hint-color: color(gray);
$typeahead-tt-menu: color(white);
$tt-box-shadow: 0 5px 10px rgba(0, 0, 0, .2) !default;
$tt-suggestion-hover-bg: theme-color(primary);
$tt-suggestion-hover-color: color(white);

$wysieditor-code-bg: #ddd !default;
$wysieditor-code-toolbar-bg: color(white);
$wysieditor-code-toolbar-color: theme-color(primary);

$timeline-badge-border-color: #fff !default;
$timeline-badge-color: $white !default;
$timeline-panel-box-shadow: 1px 2px 35px 0 rgba(1, 1, 1, 0.1) !default;

$bullet-line-list-shape-bg: color(white);
$alert-event-bg: rgba(theme-color(primary), 0.2) !default;
$chat-container-height: 950px !default;

$purchase-banner-height: 55px !default;
$purchase-banner-bg: #000 !default;
$purchase-banner-color: $white !default;