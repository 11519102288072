/* Icheck */
.icheck,
.icheck-flat,
.icheck-line,
.icheck-square {
  margin-bottom: 0.5rem;

  label {
    font-size: 0.8125rem;
    line-height: 1.5;
    margin-left: 5px;
    margin-bottom: 0;
  }
}