/* Tags */
div.tagsinput {
  padding: 15px 15px 10px;
  border-color: $border-color;
  background: lighten($card-bg, 4%);

  span.tag {
    background: $inputtag-tab-bg;
    border: 0;
    color: $inputtag-tab-color;
    padding: 6px 14px;
    font-size: 0.8125rem;
    font-family: inherit;
    line-height: 1;

    a {
      color: $inputtag-tab-color;
    }
  }
}