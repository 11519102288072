/* iCheck plugin Polaris skin
----------------------------------- */
.icheckbox_polaris,
.iradio_polaris {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 29px;
    height: 29px;
    background: url(polaris.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_polaris {
    background-position: 0 0;
}
    .icheckbox_polaris.hover {
        background-position: -31px 0;
    }
    .icheckbox_polaris.checked {
        background-position: -62px 0;
    }
    .icheckbox_polaris.disabled {
        background-position: -93px 0;
        cursor: default;
    }
    .icheckbox_polaris.checked.disabled {
        background-position: -124px 0;
    }

.iradio_polaris {
    background-position: -155px 0;
}
    .iradio_polaris.hover {
        background-position: -186px 0;
    }
    .iradio_polaris.checked {
        background-position: -217px 0;
    }
    .iradio_polaris.disabled {
        background-position: -248px 0;
        cursor: default;
    }
    .iradio_polaris.checked.disabled {
        background-position: -279px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_polaris,
    .iradio_polaris {
        background-image: url(polaris@2x.png);
        -webkit-background-size: 310px 31px;
        background-size: 310px 31px;
    }
}